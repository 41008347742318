<template>
  <div class="d-flex justify-content-end">
    <v-btn class="mr-3" @click="reset" color="error"> İptal </v-btn>
    <v-btn
      :loading="SLoading"
      :disabled="!isFormValid"
      class="mr-3"
      @click="save"
      color="primary"
    >
      Kaydet
    </v-btn>
    <v-btn
      :loading="SACLoading"
      :disabled="!isFormValid"
      color="primary"
      @click="saveAndClose"
    >
      Kaydet ve Kapat
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SMButtonGroup",
  props: {
    isFormValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SLoading: false,
      SACLoading: false,
      loader: null,
    };
  },
  mounted() {
    this.$root.$on("stopLoading", () => {
      this.SLoading = false;
      this.SACLoading = false;
      this.loader = null;
    });
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    save() {
      this.loader = "SLoading";
      this.$emit("save");
    },
    saveAndClose() {
      this.loader = "SACLoading";
      this.$emit("saveAndClose");
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
    isFormValid() {
      if (!this.isFormValid) {
        this.loader = null;
        this.SLoading = false;
        this.SACLoading = false;
      }
    },
  },
};
</script>
