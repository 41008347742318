<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-autocomplete
          class="m-1"
          hide-details
          :items="selectableHours"
          :outlined="outlined"
          :label="label ? label + ' (Saat)' : 'Saat'"
          v-model="selectedHour"
          :error-messages="errors"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          class="m-1"
          hide-details
          :items="selectableMinutes"
          :outlined="outlined"
          :label="label ? label + ' (Dakika)' : 'Dakika'"
          v-model="selectedMinute"
          :error-messages="errors"
        />
      </v-col>
    </v-row>
    <div class="text-danger mt-1" v-if="errors">
      {{ errors }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomTimePicker",
  props: {
    time: {
      type: String,
      default: null,
    },
    isValidForm: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    selectedNow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      selectedHour:
        this.time !== null
          ? this.time.split(":")[0]
          : this.$moment().format("HH"),
      selectedMinute:
        this.time !== null
          ? this.time.split(":")[1]
          : this.$moment().format("mm"),
    };
  },
  created() {
    this.$emit("update:time", `${this.selectedHour}:${this.selectedMinute}`);
  },
  computed: {
    selectableHours() {
      const hours = [];
      for (let i = 1; i <= 24; i++) {
        if (i < 10) hours.push("0" + i);
        else hours.push(i.toString());
      }
      return hours;
    },
    selectableMinutes() {
      const minutes = [];
      for (let i = 0; i < 60; i++) {
        if (i < 10) minutes.push("0" + i);
        else minutes.push(i.toString());
      }
      return minutes;
    },
  },
  watch: {
    selectedHour() {
      this.$emit("update:time", `${this.selectedHour}:${this.selectedMinute}`);
    },
    selectedMinute() {
      this.$emit("update:time", `${this.selectedHour}:${this.selectedMinute}`);
    },
  },
};
</script>
