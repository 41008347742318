<template>
  <v-dialog v-if="!loading" v-model="dialog" persistent fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <Card :title="title" v-if="dialog" modal>
      <template #close>
        <v-btn icon @click="closeNote">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-container class="px-7 mt-5" fill-height>
        <v-row>
          <v-col cols="12" md="6">
            <v-card flat outlined elevation="10">
              <v-card-title>
                <h2>Bilgiler</h2>
              </v-card-title>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text>
                  <v-textarea
                    label="Açıklama"
                    outlined
                    required
                    :rules="[(v) => !!v || 'Açıklama alanı boş bırakılamaz']"
                    v-model="currentNote.content"
                    dense
                  ></v-textarea>
                  <v-spacer></v-spacer>
                  <fragment v-if="note_type === 'call_record'">
                    <v-row justify="space-around">
                      <v-col cols="12">
                        <v-sheet outlined>
                          <SMCallResultChipGroup
                            :call_result_id="
                              parseInt(currentNote.call_result_id)
                            "
                            @update:call_result_id="
                              (result) => (currentNote.call_result_id = result)
                            "
                          />
                          <SMSubCallResultChipGroup
                            :call_result_id="
                              parseInt(currentNote.call_result_id)
                            "
                            :sub_call_result_id="
                              parseInt(currentNote.sub_call_result_id)
                            "
                            @update:sub_call_result_id="
                              (result) =>
                                (currentNote.sub_call_result_id = result)
                            "
                          />
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </fragment>
                  <v-row>
                    <v-col cols="12" style="height: 44px">
                      <v-switch v-model="hasDocument">
                        <template v-slot:label>
                          <span class="font-weight-bold">Dosya Ekle</span>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-if="hasDocument">
                    <v-col cols="12" style="height: 44px">
                      <v-file-input
                        :label="
                          disabled
                            ? 'Dosya Goruntulemek İçin Tıklayınız'
                            : 'Dosya Eklemek İçin Tıklayınız'
                        "
                        v-model="document"
                        :prepend-icon="null"
                        :required="hasDocument ? true : false"
                        :rules="[(v) => !!v || 'Dosya alanı boş bırakılamaz']"
                        :disabled="disabled"
                        outlined
                        :show-size="1000"
                        dense
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="primary accent-1"
                            dark
                            @click="disabled ? showDocumentDialog() : null"
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>
                        </template></v-file-input
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" style="height: 44px">
                      <v-switch v-model="hasReminder">
                        <template v-slot:label>
                          <span class="font-weight-bold">Hatırlatıcı Ekle</span>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-if="hasReminder">
                    <v-col cols="12">
                      <SingleReminderNew
                        :modelValue="reminder"
                        :required="hasReminder ? true : false"
                        @update:modelValue="reminder = $event"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row class="mt-3">
                    <v-col cols="12">
                      <SMButtonGroup
                        @reset="closeNote"
                        @save="save"
                        @saveAndClose="saveAndClose"
                        :isFormValid="valid"
                      />
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <NoteList
              v-if="showListOfNotes"
              :noteType="note_type"
              :noteableType="noteable_type"
              :noteableId="noteable_id"
              @change:note="getCurrentNote"
            />
          </v-col>
        </v-row>
      </v-container>
    </Card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import {
  CREATE_NOTE,
  UPDATE_NOTE,
  GET_NOTE,
} from "@/core/services/store/notes.module";
import {
  CREATE_REMINDER,
  UPDATE_REMINDER,
} from "@/core/services/store/reminders.module";

import DocumentService from "@/core/services/api/documents.service";

import Card from "@/view/content/components/Card";
import NoteList from "@/view/content/notes/NoteList.vue";
import SingleReminderNew from "@/view/content/reminders/SingleReminderNew.vue";
import SMCallResultChipGroup from "@/view/content/siliconmade/SM-Call-Result-Chipgroup.vue";
import SMSubCallResultChipGroup from "@/view/content/siliconmade/SM-Sub-Call-Result-Chipgroup.vue";
import SMButtonGroup from "@/view/content/siliconmade/SM-Button-Group.vue";

export default defineComponent({
  name: "NoteEdit",
  components: {
    Card,
    Document,
    NoteList,
    SingleReminderNew,
    SMCallResultChipGroup,
    SMSubCallResultChipGroup,
    SMButtonGroup,
  },
  props: {
    note_id: Number,
    note_type: String,
    noteable_id: Number,
    noteable_type: String,
    showListOfNotes: {
      type: Boolean,
      default: true,
    },
    clearData: {
      type: Boolean,
      default: true,
    },
    name: String,
  },
  data() {
    return {
      dialog: false,
      currentNote: {
        call_result_id: null,
        sub_call_result_id: null,
      },
      document: null,
      reminder: {},
      disabled: false,
      loading: !!this.note_id,
      valid: true,
      showNoteList: true,
      hasReminder: false,
      hasDocument: false,
      fromLeadShowPage: this.$route.name === "lead-show" ? true : false,
    };
  },
  created() {
    if (this.note_id) {
      this.getCurrentNote(this.note_id);
    }
  },
  updated() {
    if (this.showListOfNotes) {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([
      CREATE_NOTE,
      UPDATE_NOTE,
      GET_NOTE,
      CREATE_REMINDER,
      UPDATE_REMINDER,
    ]),
    closeNote() {
      this.clearNote();
      this.$emit("close");
      this.dialog = false;
    },
    clearNote() {
      if (this.clearData) {
        this.currentNote = {};
        this.reminder = {};
        this.document = null;
        this.disabled = false;
        this.note_id = null;
        this.hasDocument = false;
        this.hasReminder = false;
      }
    },
    getCurrentNote(note_id) {
      this.getNote(note_id)
        .then((note) => {
          this.currentNote = Object.assign({}, note);
          this.note_id = note.id;
          if (note.reminder) {
            this.reminder = note.reminder;
            this.hasReminder = true;
          } else {
            this.reminder = {};
            this.hasReminder = false;
          }
          if (note.document) {
            this.document = note.document;
            this.disabled = true;
            this.hasDocument = true;
          } else {
            this.document = null;
            this.disabled = false;
            this.hasDocument = false;
          }
        })
        .catch(() => {
          this.$toast.error("Not bilgileri alınamadı", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    prepareFormData() {
      const formData = new FormData();

      formData.append(
        "noteable_id",
        Number(this.$route.params.id) || this.noteable_id
      );
      formData.append("noteable_type", this.noteable_type);
      formData.append("note_type", this.note_type);
      formData.append("content", this.currentNote.content);
      if (this.note_type === "call_record") {
        formData.append("call_result_id", this.currentNote.call_result_id);
        formData.append(
          "sub_call_result_id",
          this.currentNote.sub_call_result_id
        );
      }
      formData.append("hasReminder", this.hasReminder ? 1 : 0);
      formData.append("hasDocument", this.hasDocument ? 1 : 0);
      formData.append("fromLeadShowPage", this.fromLeadShowPage ? 1 : 0);

      if (this.hasReminder) {
        formData.append("reminder[reminder_date]", this.reminder.reminder_date);
        formData.append("reminder[reminder_time]", this.reminder.reminder_time);
      }
      if (this.hasDocument) formData.append("document", this.document);
      return formData;
    },
    async save() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.valid = false;
        this.$root.$emit("stopSpinning");
        return;
      }
      const formData = this.prepareFormData();
      try {
        if (this.note_id) {
          formData.append("id", this.note_id);
          const note = await this.updateNote(formData);
          this.currentNote = Object.assign({}, note);
          const message = this.noteTypeMessage;
          this.$toast.success(message, {
            position: "bottom-right",
            duration: 2000,
          });
        } else {
          const note = await this.createNote(formData);
          this.currentNote = Object.assign({}, note);
          if (this.currentNote.document) {
            this.hasDocument = true;
            this.disabled = true;
          }
          this.note_id = note.id;
          const message = this.noteTypeMessage;
          this.$toast.success(message, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } catch (err) {
        this.$toast.error(err[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
      this.$root.$emit("stopSpinning");
    },
    async saveAndClose() {
      await this.save();
      if (this.valid) this.closeNote();
    },
    showDocumentDialog() {
      const path = DocumentService.createPreviewLinkOfDocument(this.document);
      const params = {
        fileURL: path,
        fileName: this.document.name,
        mimeType: this.document.mime_type,
      };
      this.$documentReader.show(params);
    },
  },
  computed: {
    title() {
      if (this.note_id && this.note_type === "note")
        return this.name + " - Not Düzenle";
      else if (!this.note_id && this.note_type === "note")
        return this.name + " - Not Ekle";
      else if (this.note_id && this.note_type === "call_record")
        return this.name + " - Görüşme Kaydı Düzenle";
      else return this.name + " - Görüşme Kaydı Ekle";
    },
    noteTypeMessage() {
      return this.note_type === "call_record"
        ? "Görüşme kaydı güncellendi"
        : "Not güncellendi";
    },
    hasDocument(val) {
      if (!val) {
        this.document = null;
        this.disabled = false;
      }
    },
  },
});
</script>

<style lang="scss"></style>
