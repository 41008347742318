<template>
  <div v-if="!loading">
    <v-sheet class="pa-3" outlined>
      <span>Görüşme Kaydı Sonuç Kodu</span>
    </v-sheet>
    <div class="p-3">
      <v-chip-group
        active-class="primary"
        column
        justify="center"
        :required="required"
        v-model="selectedCallRecordResult"
      >
        <v-chip
          v-for="record in callRecordResults"
          :key="record.id"
          :value="record.id"
          small
        >
          {{ record.text }}
        </v-chip>
      </v-chip-group>
      <v-select
        :value="selectedCallRecordResult"
        :items="callRecordResults"
        style="display: none"
        :rules="[(v) => !!v || 'Görüşme kaydı sonuç kodu seçiniz']"
        required
      ></v-select>
    </div>
  </div>
  <div v-else>
    <span>Yükleniyor...</span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  name: "SMCallResultChipgroup",
  props: {
    call_result_id: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      callRecordResults: [],
      loading: true,
    };
  },
  mounted() {
    this.getCallRecordResults()
      .then((data) => {
        this.callRecordResults = data;
      })
      .catch(() => {
        this.$toast.error("Görüşme kaydı sonuç kodları alınamadı.", {
          position: "bottom-right",
          duration: 2000,
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getCallRecordResults() {
      return new Promise((resolve, reject) => {
        AxiosInstance.get("call-results")
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  computed: {
    selectedCallRecordResult: {
      get() {
        return this.call_result_id;
      },
      set(val) {
        this.$emit("update:call_result_id", val);
        this.$root.$emit("changeCallResult", val);
      },
    },
  },
});
</script>
