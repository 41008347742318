<template>
  <v-card flat outlined elevation="10" v-if="!loading">
    <v-card-title>
      <h2>{{ noteOrRecordTitle }}</h2>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="filteredTableHeaders"
        :items="notes"
        :search="search"
        :loading="loading"
        :header-props="{ sortIcon: null }"
        v-model="selectedNote"
      >
        <template v-slot:item.document_id="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.document_id ? 'primary' : 'grey lighten-2'"
                @click="showDocumentDialog(item.document)"
                v-on="on"
                v-bind="attrs"
              >
                mdi-file-document
              </v-icon>
            </template>
            <span v-if="item.document_id">Dokümanı Görüntüle</span>
            <span v-else>Doküman Yok</span>
          </v-tooltip>
        </template>
        <template v-slot:item.content="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="item.content.length > 30 ? on : {}">{{
                item.content.length > 30
                  ? item.content.substring(0, 30) + "..."
                  : item.content
              }}</span>
            </template>
            <span>{{ item.content }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="warning"
                @click="() => $emit('change:note', item.id)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{ returnLocaleDate(item.created_at) }}</span>
        </template>
        <template v-slot:item.call_result_id="{ item }">
          <span v-if="item.call_result_id">{{ item.call_result.text }}</span>
        </template>
        <template v-slot:item.sub_call_result_id="{ item }">
          <span v-if="item.sub_call_result_id">{{
            item.sub_call_result.text
          }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FETCH_NOTES } from "@/core/services/store/notes.module";
import { defineComponent } from "vue";

import DocumentService from "@/core/services/api/documents.service";
import Note from "@/view/content/notes/Note.vue";

export default defineComponent({
  components: { Note },
  props: {
    noteableId: {
      type: Number,
      required: true,
    },
    noteable_type: {
      type: String,
      required: true,
    },
    noteType: {
      String,
      required: true,
      validator: (value) => ["call_record", "note"].includes(value),
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  beforeMount() {
    this.getLeadNotes();
  },
  methods: {
    ...mapActions([FETCH_NOTES]),
    getLeadNotes() {
      this.fetchNotes({
        noteable_id: this.noteableId,
        note_type: this.noteType,
        noteable_type: this.noteable_type,
      }).then(() => {
        this.loading = false;
      });
    },
    showDocumentDialog(doc) {
      if (!doc) return;
      const path = DocumentService.createPreviewLinkOfDocument(doc);
      const params = {
        fileURL: path,
        fileName: doc.name,
        mimeType: doc.mime_type,
      };
      this.$documentReader.show(params);
    },
    returnLocaleDate(date) {
      return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  computed: {
    ...mapGetters(["notes"]),
    filteredTableHeaders() {
      const headers = [
        { text: "Tarih", value: "created_at", align: "left" },
        { text: "Açıklama", value: "content", align: "left" },
        { text: "Döküman", value: "document_id", align: "left" },
        { text: "Düzenle", value: "edit", align: "left" },
      ];
      if (this.noteType === "call_record") {
        const callResult = {
          text: "Sonuç",
          value: "call_result_id",
          align: "left",
        };
        const subCallResult = {
          text: "Alt Sonuç",
          value: "sub_call_result_id",
          align: "left",
        };
        headers.splice(1, 0, callResult, subCallResult);
      }
      return headers;
    },
    noteOrRecordTitle() {
      return this.noteType === "call_record"
        ? "Geçmiş Kayıtlar"
        : "Geçmiş Notlar";
    },
  },
});
</script>

<style></style>
