<template>
  <v-row style="height: 80px">
    <v-col cols="12" md="7">
      <CustomDatePicker
        :value="reminder_date"
        @update:value="(val) => (reminder_date = val)"
        :outlined="true"
        :selectedToday="note_id ? false : true"
        clearable
      >
      </CustomDatePicker>
    </v-col>
    <v-col cols="12" md="5">
      <CustomTimePicker
        :time="reminder_time"
        @update:time="(val) => (reminder_time = val)"
        clearable
        outlined
      />
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "vue";

import CustomDatePicker from "@/view/content/components/CustomDatePicker.vue";
import CustomTimePicker from "@/view/content/components/CustomTimePicker.vue";
export default defineComponent({
  name: "SingleReminder",
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      timePickerMenu: false,
    };
  },
  computed: {
    reminder: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
      deep: true,
    },
    reminder_time: {
      get() {
        return this.reminder.reminder_time;
      },
      set(value) {
        this.reminder.reminder_time = value;
      },
    },
    reminder_date: {
      get() {
        return this.reminder.reminder_date;
      },
      set(value) {
        this.reminder.reminder_date = value;
      },
    },
  },
  components: {
    CustomDatePicker,
    CustomTimePicker,
  },
});
</script>

<style lang="scss">
.v-picker--time {
  height: 300px !important;
}
</style>
