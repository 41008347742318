<template>
  <div v-if="!loading">
    <v-sheet class="pa-3" outlined>
      <span>Görüşme Kaydı Alt Sonuç Kodu</span>
    </v-sheet>
    <div class="p-3">
      <v-chip-group
        active-class="primary"
        column
        justify="center"
        v-model="selectedSubCallRecordResult"
        :required="required"
      >
        <v-chip
          v-for="subRecord in subCallRecordResults"
          :key="subRecord.id"
          :value="subRecord.id"
          small
        >
          {{ subRecord.text }}
        </v-chip>
      </v-chip-group>
      <v-select
        :value="selectedSubCallRecordResult"
        :items="subCallRecordResults"
        style="display: none"
        :rules="[(v) => !!v || 'Görüşme kaydı alt sonuç kodu seçiniz']"
        required
      ></v-select>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import AxiosInstance from "@/core/services/axios.service";

export default defineComponent({
  name: "SMSubCallResultChipgroup",
  props: {
    call_result_id: {
      type: Number,
      default: null,
    },
    sub_call_result_id: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subCallRecordResults: [],
      loading: true,
      errors: [],
    };
  },
  mounted() {
    this.$root.$on("changeCallResult", (call_result_id) => {
      this.call_result_id = call_result_id;
    });
  },
  created() {
    if (this.sub_call_result_id) {
      this.getSubCallRecordResults(this.call_result_id)
        .then((data) => {
          this.subCallRecordResults = data;
          if (this.subCallRecordResults.length > 0) {
            let subcall = this.subCallRecordResults.find(
              (subCallRecordResult) =>
                subCallRecordResult.id === this.sub_call_result_id
            );
            if (subcall) {
              this.selectedSubCallRecordResult = subcall.id;
            }
          }
        })
        .catch(() => {
          this.$toast.error("Görüşme kaydı alt sonuç kodları alınamadı.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    getSubCallRecordResults(call_result_id) {
      return new Promise((resolve, reject) => {
        AxiosInstance.get("sub-call-results", {
          params: {
            call_result_id: call_result_id,
          },
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  computed: {
    ...mapGetters(["note"]),
    selectedSubCallRecordResult: {
      get() {
        return this.sub_call_result_id;
      },
      set(value) {
        this.$emit("update:sub_call_result_id", value);
      },
    },
  },
  watch: {
    call_result_id(value) {
      this.getSubCallRecordResults(value)
        .then((data) => {
          this.subCallRecordResults = data;
          if (this.subCallRecordResults.length > 0) {
            let subcall = this.subCallRecordResults.find(
              (subCallRecordResult) =>
                subCallRecordResult.id === this.sub_call_result_id
            );
            if (subcall) {
              this.selectedSubCallRecordResult = subcall.id;
            }
          }
          this.$forceUpdate();
        })
        .catch(() => {
          this.$toast.error("Görüşme kaydı alt sonuç kodları alınamadı.", {
            position: "bottom-right",
            duration: 2000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
